/* eslint-disable react/jsx-sort-props */
/* eslint-disable sort-keys-fix/sort-keys-fix */
import React from "react";
import { Grid, Typography } from "@mui/material";
import { StyledContainer } from "./styles";

export type PpMetrics = {
  metrics: Array<{
    name: string;
    value: string;
  }>;
  withoutPaddingTop?: boolean;
};

export function Metrics({
  metrics,
  withoutPaddingTop = false,
  ...rest
}: PpMetrics): React.ReactElement {
  return (
    <StyledContainer $withoutPaddingTop={withoutPaddingTop} {...rest}>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        spacing={{ xs: 2, md: 0 }}
      >
        {metrics.map(({ name, value }) => (
          <Grid key={name} item xs={6} md="auto">
            <Grid container alignItems="center" direction="column">
              <Typography align="center" color="primary" variant="h1">
                {value}
              </Typography>
              <Typography
                align="center"
                color="site.metrics.name"
                variant="caption"
              >
                {name}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </StyledContainer>
  );
}
