import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Link, SectionHeading } from "@italymondo/core-ui";
import { Grid } from "@mui/material";

export const CardsContainerDesktop = styled(Grid)`
  ${({ theme }): SerializedStyles => css`
    margin-top: ${theme.spacing(2)};
  `}
`;

export const Heading = styled(SectionHeading)`
  ${({ theme }): SerializedStyles => css`
    text-align: center;
    ${theme.breakpoints.between("sm", "lg")} {
      max-width: 450px;
    }
    ${theme.breakpoints.up("lg")} {
      text-align: left;
      max-width: 66%;
    }
  `}
`;

export const StyledLink = styled(Link)`
  display: inline-block;
`;

export const CardsContainerMobile = styled(Grid)`
  ${({ theme }): SerializedStyles => css`
    margin-top: ${theme.spacing(0)};
  `}
`;

export const ToggleBtnGroupContainer = styled(Grid)`
  max-width: 450px !important;
  width: 100%;
  .MuiToggleButtonGroup-root {
    width: 100%;
    > button {
      flex: 1;
    }
  }
`;
