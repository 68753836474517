import React from "react";
import { CardContent, Grid, Typography } from "@mui/material";
import { getImage, ImageDataLike } from "gatsby-plugin-image";
import { Link } from "../../links";
import {
  CardImage,
  StyledCard,
  StyledCardActions,
  Summary,
  Title,
} from "./styles";

export type PpImageCard = {
  category?: string;
  date?: string;
  image: {
    alt: string;
    src: ImageDataLike;
  };
  link: {
    label: string;
    url: string;
  };
  summary: string;
  title: string;
};

export function ImageCard({
  category,
  date,
  image,
  link,
  summary,
  title,
}: PpImageCard): React.ReactElement {
  const gImage = getImage(image.src);
  return (
    <StyledCard key={title}>
      <CardImage
        alt={`${title}-image`}
        image={gImage}
        imgClassName="card-image"
      />
      <CardContent>
        {date && category ? (
          <Grid container spacing={3}>
            <Grid item>
              <Typography color="primary" variant="caption">
                {date}
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="primary.light" variant="caption">
                {category}
              </Typography>
            </Grid>
          </Grid>
        ) : null}
        <Title gutterBottom color="primary" component="h1" variant="h3">
          {title}
        </Title>
        <Summary color="primary" component="p" variant="card--desc">
          {summary}
        </Summary>
      </CardContent>
      <StyledCardActions>
        <Link withArrow color="primary" to={link.url} variant="caption">
          {link.label}
        </Link>
      </StyledCardActions>
    </StyledCard>
  );
}
