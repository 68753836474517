import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Card, CardActions, Typography } from "@mui/material";
import { GatsbyImage } from "gatsby-plugin-image";

export const StyledCard = styled(Card)`
  ${({ theme }): SerializedStyles => css`
    ${theme.breakpoints.down("md")} {
      ${theme.cssMixins.toggleInAnimation({ duration: 400 })};
    }
    ${theme.breakpoints.up("md")} {
      height: 508px;
      .link-arrow {
        display: inline-block;
      }
      .link-arrow {
        transition: ${theme.transitions.create("all", {
          duration: theme.transitions.duration.complex,
        })} !important;
      }
      &:hover {
        .link-arrow {
          transform: translateX(${theme.spacing(1)});
        }
      }
    }
  `}
`;

export const CardImage = styled(GatsbyImage)`
  ${({ theme }): SerializedStyles => css`
    .card-image,
    img,
    div[data-placeholder-image] {
      border-radius: ${theme.shape.siteBorderRadius.lg}
        ${theme.shape.siteBorderRadius.lg} 0 0;
      overflow: hidden;
    }
    height: 190px;
    ${theme.breakpoints.up("sm")} {
      height: 300px;
    }
  `}
`;

export const StyledCardActions = styled(CardActions)`
  ${({ theme }): SerializedStyles => css`
    ${theme.breakpoints.up("md")} {
      position: absolute;
      bottom: 0;
    }
  `}
`;

export const Title = styled(Typography)`
  ${({ theme }): SerializedStyles => css`
    margin-top: ${theme.spacing(0.5)};
    padding-bottom: ${theme.spacing(0.5)};
    ${theme.cssMixins.lineClamp({ lines: 1 })};
  `}
`;

export const Summary = styled(Typography)`
  ${({ theme }): SerializedStyles => css`
    ${theme.cssMixins.lineClamp({ lines: 3 })};
  `}
`;
