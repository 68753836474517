/* eslint-disable sort-keys-fix/sort-keys-fix */
import React, { useState } from "react";
import {
  ImageCard,
  PpImageCard,
  SectionContainer,
  ToggleBtnGroup,
} from "@italymondo/core-ui";
import { Grid, Stack } from "@mui/material";
import {
  CardsContainerDesktop,
  CardsContainerMobile,
  Heading,
  StyledLink,
  ToggleBtnGroupContainer,
} from "./styles";

type ServiceType = PpImageCard & {
  label: string;
};

export type PpServices = {
  description: string;
  sectionName?: string;
  services: Array<ServiceType>;
  title: string;
};

export function Services({
  description,
  sectionName,
  services,
  title,
}: PpServices): React.ReactElement {
  const [currentService, setCurrentService] = useState<ServiceType>(
    services[0]
  );

  function handleServiceChange(newValue: string): void {
    const newService = services.find(
      (s: ServiceType): boolean => s.label === newValue
    );
    if (newService) setCurrentService(newService);
  }

  return (
    <SectionContainer>
      <Stack
        alignItems={{ lg: "flex-start", xs: "center" }}
        justifyContent="flex-start"
      >
        <Heading
          alignItems={{ lg: "flex-start", xs: "center" }}
          description={description}
          sectionName={sectionName}
          title={title}
        />
        <Grid sx={{ display: { lg: "flex", xs: "none" } }}>
          <CardsContainerDesktop container spacing={4}>
            {services.map((s) => (
              <Grid key={s.title} item xs={4}>
                <StyledLink to={s.link.url}>
                  <ImageCard
                    image={s.image}
                    link={s.link}
                    summary={s.summary}
                    title={s.title}
                  />
                </StyledLink>
              </Grid>
            ))}
          </CardsContainerDesktop>
        </Grid>
        <Grid sx={{ display: { lg: "none", xs: "flex" } }}>
          <CardsContainerMobile
            container
            alignItems="center"
            direction="column"
            spacing={3}
          >
            <ToggleBtnGroupContainer item>
              <ToggleBtnGroup
                value={currentService.label}
                values={services.map((s) => ({ name: s.label }))}
                onChange={handleServiceChange}
              />
            </ToggleBtnGroupContainer>
            <Grid item>
              <StyledLink to={currentService.link.url}>
                <ImageCard
                  image={currentService.image}
                  link={currentService.link}
                  summary={currentService.summary}
                  title={currentService.title}
                />
              </StyledLink>
            </Grid>
          </CardsContainerMobile>
        </Grid>
      </Stack>
    </SectionContainer>
  );
}
