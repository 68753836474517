import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { SectionContainer } from "../containers";
import { shouldForwardProp } from "../utils";

type PpContainer = {
  $withoutPaddingTop: boolean;
};

export const StyledContainer = styled(SectionContainer, {
  shouldForwardProp,
})<PpContainer>`
  ${({ $withoutPaddingTop }): SerializedStyles => css`
    ${$withoutPaddingTop &&
    css`
      padding-top: 0 !important;
    `};
  `}
`;
